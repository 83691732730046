<template>
    <div>
      <!-- {{row.local_order.list}} -->
      <el-popover
        placement="top-start"
        width="460"
        trigger="hover"
        v-if="row.statistics"
      >
        <div :id="`echarts${id}`" style="width:440px;height:200px"></div>
        <el-table
          :data="dailyStatsDesc"
          border
          max-height="260"
          style="font-size:12px;width:100%"
        >
          <el-table-column
            property="dateStr"
            label="日期"
            width="90"
          ></el-table-column>
          <el-table-column property="orderCount" label="订单/单次">
            <template slot-scope="scoped">
              <span style="font-size: 14px; font-weight: 700;">{{ scoped.row.orderCount }}</span> / {{ scoped.row.perOrderCost }}
            </template>
          </el-table-column>
          <el-table-column property="spend" label="花费"></el-table-column>
        </el-table>
        <div slot="reference">
            <span><span style="font-weight: 700;font-size: 18px;">{{ row.statistics.orderCount }}</span>/{{row.statistics.perOrderCost.toFixed(2)}}</span
            >
          <!-- <p>
            {{
              row.local_order
                ? `${row.local_order.validOrderCount}/${row.local_order.orderCount}`
                : "--"
            }}
          </p>
          <p
            class="color9"
            style="height: 20px; line-height: 20px"
            v-if="row.spend && row.local_order && row.local_order.validOrderCount"
          >
            成本：{{spend}}
          </p> -->
        </div>
        <!-- <el-button slot="reference">hover 激活</el-button> -->
      </el-popover>
      <p v-else>--</p>
    </div>
  </template>
  <script>
  export default {
    props: {
      row: {
        type: Object,
        default: () => {},
      },
      id: {
        type: [String, Number],
        default: "",
      },
    },
    data() {
      return {
      };
    },
    computed: {
      spend(){
        if(this.row.spend_usd){
          let spend = this.row.spend_usd.slice(1)
          return '$' + ((spend/this.row.local_order.validOrderCount).toFixed(1)) + '/$' + ((spend/this.row.local_order.orderCount).toFixed(1))
        }else{
          return (this.row.spend/this.row.local_order.validOrderCount).toFixed(1) + '/' + (this.row.spend/this.row.local_order.orderCount).toFixed(1)
        }
      },
      dailyStatsDesc() {
        return this.row?.statistics?.hisStat || []
      },
      dailyStats() {
        return this.row?.statistics?.hisStat || []
      }
    },
    mounted() {
        if (this.dailyStats.length > 0) {
            this.drawEcharts()
        }
    },
    methods: {
      drawEcharts() {
        var chartDom = document.getElementById(`echarts${this.id}`);
        var myChart = this.$echarts.init(chartDom);
        myChart.clear()
        var option;
        option = {
          title: {
            // text: "订单量展示",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["订单", "花费"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: this.dailyStats.map(v=>v.dateStr),
            axisLabel: {
                interval: 0,
                rotate: 10
            }
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "订单",
              type: "line",
              // stack: "总量",
              data: this.dailyStats.map(v=>v.orderCount),
            },
            {
              name: "花费",
              type: "line",
              // stack: "总量",
              data: this.dailyStats.map(v=>v.spendRmb),
            }
          ],
        }
        myChart.setOption(option);
      },
    }
  };
  </script>
  <style scoped lang="scss"></style>
  