<template>
    <el-dialog
        title="花费趋势"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="60%"
        :modal-append-to-body="false"
        :append-to-body="true">
        <div id="spendEcharts" style="width:100%;height:300px" v-loading="loading"></div>
    </el-dialog>
</template>

<script>
import { spendHours } from "@/api/multiPlatform"

export default {
    data() {
        return {
            dialogVisible: false,
            adInfo: {},
            echartsData: [],
            loading: false
        }
    },
    methods: {
        show(val) {
            console.log(val)
            this.adInfo = val
            this.dialogVisible = true
            this.getEchartsData()
        },
        hide() {
            this.dialogVisible = false
        },
        getEchartsData() {
            this.loading = true
            let request = {
                platform: this.adInfo.platform,
                ad: {
                    platform: this.adInfo.platform,
                    credentialId: this.adInfo.credentialId,
                    adAccountId: this.adInfo.adAccountId,
                    campaignId: this.adInfo.campaignId
                }
            }
            spendHours(request).then(res => {
                this.loading = false
                if (res.code === 0) {
                    this.echartsData = res.data.reverse()
                    this.initEcharts()
                }
            }).catch(() => {
                this.loading = false
            })
        },
        initEcharts() {
            let chartDom = document.getElementById(`spendEcharts`);
            let myChart = this.$echarts.init(chartDom);
            myChart.clear()
            let option;
            option = {
            title: {
                // text: "订单量展示",
            },
            tooltip: {
                trigger: "axis",
            },
            legend: {
                data: ["订单", "花费"],
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: {
                type: "category",
                data: this.echartsData.map(v=>v.dataHours)
            },
            yAxis: {
                type: "value",
            },
            series: [
                {
                    name: "订单",
                    type: "line",
                    // stack: "总量",
                    data: this.echartsData.map(v=>v.orderCount),
                },
                {
                    name: "花费",
                    type: "line",
                    // stack: "总量",
                    data: this.echartsData.map(v=>v.spend),
                }
            ],
            }
            myChart.setOption(option);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>