<template>
  <el-dialog
    :visible.sync="show"
    width="500px"
    :title="title"
    @close="close"
    @opened="openInit"
  >
    <div v-loading="loading">
      <el-date-picker
        v-model="dateVal"
        type="datetime"
        placeholder="选择日期"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions"
        style="width: 100%"
      >
      </el-date-picker>
      <div class="dp-flex justify-end mt-20">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { settingAdScheduleOpenTask } from '@/api/multiPlatform';
export default {
  props: {
    show: Boolean,
    status: Boolean,
    ids: Array,
    defaultData: String,
    level: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dateVal: '',
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
        start: '00:00',
        step: '00:30',
        end: '23:30',
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    title() {
      return `定时${this.status ? '开' : '关'}`;
    },
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    openInit() {
      if (this.defaultData) {
        return (this.dateVal = moment(this.defaultData).format('YYYY-MM-DD HH:mm:ss'));
      }
      // 定时开
      if (this.status) {
        this.dateVal = moment().add(1, 'days').format('YYYY-MM-DD 00:00:00');
      } else {
        // 定时关
        this.dateVal = moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      }
    },
    sure() {
      if (!this.dateVal) {
        return this.$message.error('请选择日期');
      }
      let idKey = {
        campaign: 'campaignId',
        adset: 'adGroupId',
        ad: 'adId',
      };
      let type = this.status ? 1 : 4;
      let data = {
        handleTime: this.dateVal,
        clientType: 'mobile',
        scheduleType: type,
        gimpUserId: this.userInfo.id,
        adInfos: this.ids.map((item) => ({
          id: item.ad[idKey[this.level]],
          level: this.level,
          platform: item.ad.platform,
          adAccountId: item.ad.adAccountId,
          campaignId: item.ad.campaignId,
          adGroupId: item.ad.adGroupId,
          adGroupAdId: item.ad.adId,
        })),
      };
      if (this.loading) return;
      this.loading = true;
      settingAdScheduleOpenTask(data)
        .then((res) => {
          this.ids.map((item, index) => {
            let oldOPenSchedule = (this.ids[index].ad.open_schedule||[]).filter((item) => item.type != type);
            const newOpenSchedule = [...res.data, ...oldOPenSchedule].filter(
              (schedule) => schedule.adId == item.ad[idKey[this.level]],
            );
            this.$set(this.ids[index].ad, 'open_schedule', [...newOpenSchedule]);
          });
          this.$message.success('修改成功');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
